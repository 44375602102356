import { graphql } from "gatsby";
import { BlockMdxContent, Section } from "../components";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";

interface PageProps {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero },
      body,
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    <main>
      <Section marginTop marginBottom>
        <BlockMdxContent body={body} hero={hero} />
      </Section>
    </main>
  </PageLayout>
);

export const query = graphql`
  query PrivacyPolicyQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/privacy-policy.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
        }
      }
      body
    }
  }
`;
